// *******************************************************************************
// Open radio dropdown
// *******************************************************************************

const radioButtons = document.querySelectorAll('.cart-modal__radios .radio-button');
const secondAddress = document.querySelector('#radio-second-address');
const secondAddressDropdown = document.querySelector('.second-address');

if (radioButtons) {
  radioButtons.forEach((radioButton) => {
    radioButton.addEventListener('click', () => {
      if (secondAddress.checked) {
        secondAddressDropdown.classList.add('is-open');
      } else {
        secondAddressDropdown.classList.remove('is-open');
      }
    });
  });
}

// *******************************************************************************
// Open checkbox dropdown
// *******************************************************************************
const giftCheckbox = document.querySelector('#checkbox-gift');
const giftTextarea = document.querySelector('.gift-textarea');

if (giftCheckbox) {
  giftCheckbox.addEventListener('click', () => {
    if (giftCheckbox.checked) {
      giftTextarea.classList.add('is-open');
    } else {
      giftTextarea.classList.remove('is-open');
    }
  });
}

// *******************************************************************************
// Open modal
// *******************************************************************************
const openCartModals = document.querySelectorAll('.open-cart-modal');
const closeCartModals = document.querySelectorAll('.close-cart-modal');
const switchModals = document.querySelector('.switch-modals');
const closeSuccessModal = document.querySelector('.close-success-modal');
const cartModal = document.querySelector('.cart-modal');
const successModal = document.querySelector('.success-modal');

if (openCartModals) {
  openCartModals.forEach((openCartModal) => {
    openCartModal.addEventListener('click', () => {
      cartModal.classList.add('is-open');
      document.body.classList.add('noscroll');
    });
  });
}

if (closeCartModals) {
  closeCartModals.forEach((closeCartModal) => {
    closeCartModal.addEventListener('click', () => {
      cartModal.classList.remove('is-open');
      document.body.classList.remove('noscroll');
    });
  });
}

if (switchModals) {
  switchModals.addEventListener('click', () => {
    cartModal.classList.remove('is-open');
    successModal.classList.add('is-open');
  });
}

if (closeSuccessModal) {
  closeSuccessModal.addEventListener('click', () => {
    successModal.classList.remove('is-open');
    document.body.classList.remove('noscroll');
  });
}

// *******************************************************************************
// Word count
// *******************************************************************************
const textarea = document.querySelector('.gift-textarea textarea');
const wordCounter = document.querySelector('.word-counter');

if (wordCounter) {
  textarea.oninput = function checkLength() {
    wordCounter.innerHTML = (textarea.value.length);
  };
}
